<template>
    <div align="center" class="grey-background">
    
      <h4> {{$t('SelfiewithyourStudentID')}}</h4> 
      <p>{{$t('Makesureyourfaceisintheovalarea')}}</p>
      
      <div v-if="isCameraOpen" v-show="!isLoading" :class="{ 'flash' : isShotPhoto }">
        <div class="camera-shutter" :class="{'flash' : isShotPhoto}"></div>
        <video v-show="!isPhotoTaken" ref="camera" class="camera-stream" :width="250" :height="337.5" autoplay>
        </video>
      <br>
      <p>{{$t('Camera2times')}}</p>
      </div>
    
      <!-- <div v-if="isCameraOpen && !isLoading && !isPhotoTaken" class="camera-shoot">
        <v-btn
          @click="takePhoto"
              color="primary"
              fab
              small
              dark
            >
            <v-icon>mdi-camera</v-icon>
          </v-btn>
      </div> -->
      <div v-if="isCameraOpen && !isLoading && !isPhotoTaken" 
        class="camera-shoot">
        <v-img 
        class="camera-img-capture"
        width="80px"
        height="80px"  
        :src="require('../../assets/kyc/buttonCamera.svg')"
        @click="takePhoto()">
        </v-img>

        <v-img
        class="camera-img-split"
        width="40px"
        height="40px"  
        :src="require('../../assets/kyc/camera-back.svg')"
        @click="backCamera()">
        </v-img>

      </div>
      <div v-show="isPhotoTaken" align="center" class="white-background">
        <br>  
        <div v-show="isPhotoTaken">
          <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" :width="250" :height="337.5"></canvas>
        </div>

        <div v-if="isPhotoTaken && isCameraOpen">
          
            <v-btn
            @click="takePhoto"
                color="primary"
                small
                dark
                text
              >
              {{$t('RepeatPhoto')}}
            </v-btn>
        </div>

        <div class="school-information" v-if="isPhotoTaken && isCameraOpen" >
            <v-btn
            class="button-school-information"
            @click="Confirmation()"
                color="primary"
                normal
                dark
                rounded
              >
              {{$t('Submit')}}
            </v-btn>
        </div>
      </div>
    </div>
</template>
<script>
// import NotificationPopup from "@/components/NotificationPopup.vue";

  export default {
    name: 'CameraOnSelfieIdentityCard',
    components: { 
    // NotificationPopup,
    // WebCam,
    },
    data() {
      return {
        // notifSuccess:false,
        // notifFailed:false,
        // dialogConfirmationSubmit:false,
        email:"",
        schoolId:"",
        isCameraOpen: true,
        isPhotoTaken: false,
        isShotPhoto: false,
        isLoading: false,
        link: '#',
        photo_selfie : this.$store.state.cdn.photo_selfie_id,
        dataResponse:"",
      }
    },
    mounted(){
      this.isCameraOpen = true;
      this.frontCamera();
    },
    methods:{
      backCamera(){

        this.isLoading = true;
      
        const constraints = (window.constraints = {
          audio: false,
          video:{facingMode:{exact:"environment"}}
        });
        
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(stream => {
            this.isLoading = false;
            this.$refs.camera.srcObject = stream;
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false;
            alert("May the browser didn't support or there is some errors.");
          });
      },
      frontCamera(){
        this.isLoading = true;
          const constraints = (window.constraints = {
            audio: false,
            video : {facingMode:"user"}
          });

        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(stream => {
            this.isLoading = false;
            this.$refs.camera.srcObject = stream;
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false;
            alert("May the browser didn't support or there is some errors.");
          });
      },
      b64toBlob(b64Data, contentType, sliceSize) {
          contentType = contentType || '';
          sliceSize = sliceSize || 100;

          var byteCharacters = atob(b64Data);
          var byteArrays = [];
          
          for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
              var slice = byteCharacters.slice(offset, offset + sliceSize);

              var byteNumbers = new Array(slice.length);
              for (var i = 0; i < slice.length; i++) {
                  byteNumbers[i] = slice.charCodeAt(i);
              }

              var byteArray = new Uint8Array(byteNumbers);
          
              byteArrays.push(byteArray);
          }

       var blob = new Blob(byteArrays, {type: contentType});
        return blob;
      },
      Confirmation(){
        var canvas = document.getElementById("photoTaken");
        var img    = canvas.toDataURL("image/jpeg");
        var block = img.split(";");

        // Get the content type of the image
        var contentType = block[0].split(":")[1];// In this case "image/gif"
        // get the real base64 content of the file
        var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."

        // Convert it to a blob to upload
        var blob = this.b64toBlob(realData, contentType);

        // console.log('Encoded Binary File String:', blob);

         let payload = {
            file: blob,
            filename: this.$store.state.auth.userData.user_name + "_selfie_id_card.jpg",
        }
        console.log(payload)
        this.$store
        .dispatch("cdn/uploadPhotoSelfieID", payload)
        .then((response) => {
          if (response.status == true) {
            this.dataResponse =  response.photo
          }
        }).then(() =>{
          if (this.dataResponse == ""){
            this.notifFailed = true
          }else{
          let payloadKyc = {
            photo_passenger_id_card: this.dataResponse,
          }

          this.$store
            .dispatch("passenger/selfieIdCardKyc", payloadKyc)
            .then((resp) => {
              if (resp.status == true) {
                this.$router.push({
                  name: 'CameraOnSelfie',
                })
              }
            })
            .catch((err) => {
              console.log(err);
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });

      },
      createCameraElement() {
        this.isLoading = true;
        
        const constraints = (window.constraints = {
          audio: false,
          video: true
        });


        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(stream => {
            this.isLoading = false;
            this.$refs.camera.srcObject = stream;
          })
          .catch(error => {
            this.isLoading = false;
            console.log(error)
            alert("May the browser didn't support or there is some errors.");
          });
      },
      stopCameraStream() {
        let tracks = this.$refs.camera.srcObject.getTracks();

        tracks.forEach(track => {
          track.stop();
        });
      },

      takePhoto() {
        if(!this.isPhotoTaken) {
          this.isShotPhoto = true;

          const FLASH_TIMEOUT = 50;

          setTimeout(() => {
            this.isShotPhoto = false;
          }, FLASH_TIMEOUT);
        }
        
        this.isPhotoTaken = !this.isPhotoTaken;
        const context = this.$refs.canvas.getContext('2d');
        context.drawImage(this.$refs.camera, 0, 0, 250, 337.5);
        // console.log("data",context.drawImage(this.$refs.camera, 0, 0, 450, 337.5))
      },
    },
  }
</script>

<style lang="scss" scoped>

/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
  .camera-stream {
    width: 50%;
    max-height: 70%;
    border-radius: 50%;
    border: 5px solid #FFFFFF;
  }

  .camera-shutter {
  }

  .grey-background {
    position: flex;
    display: block;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;

    background-color: #646464;
    color: #ffffff;
  }

  .white-background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;

    background: #ffffff;
  }
  
  
  .camera-shoot {
    position: fixed;
    left: 45%;
    bottom: 0;
    width: auto;
    text-align: center;
    margin: 1rem 0; 
    cursor: pointer;
  }
  
  .camera-img-capture{
    margin-top: -30px;  
  }
  .camera-img-split{
    bottom: 0;
    right: -130px;
    margin-top: -45px;  
  }
  
  .school-information{
    width: 100%;
    padding-top: 17%;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
  }

 .button-school-information{
    width: 100%;
    color:#4BB14E;
  }
}

/* If the screen max size is 600px, set the font-size of <div> to 80px */
@media only screen and (max-width: 600px) {
  .camera-stream {
    width: 50%;
    max-height: 60%;
    border-radius: 50%;
    border: 5px solid #FFFFFF;
  }

  .camera-shutter {
  }

  .grey-background {
    position: flex;
    display: block;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;

    background-color: #646464;
    color: #ffffff;
  }

  .white-background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;

    background: #ffffff;
  }
  
  
  .camera-shoot {
    position: fixed;
    left: 170px;
    bottom: 0;
    width: auto;
    text-align: center;
    margin: 1rem 0; 
    cursor: pointer;
  }
  
  .camera-img-capture{
    margin-top: -30px;  

  }
  .camera-img-split{
    bottom: 0;
    right: -130px;
    margin-top: -45px;  
  }
  
  .school-information{
    width: 100%;
    padding-top: 17%;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
  }

 .button-school-information{
    width: 100%;
    color:#4BB14E;
  }
}
</style>